<template>
    <div>        
        <div class="useleeWidget-overflow-hidden" style="border-radius: 13px;" id="uselee-widget" widget="ListWA" :authkey="$api.uselee" meta='{"User":1}'></div>    
    </div>
</template>
<script>    
    export default {
        name: "WhatsApp",
        components: {},
        computed: {},
        data() {
            return {
                ready: false,                                
            }
        },
        beforeMount(){            
        },
        mounted() {  
            setTimeout(() => {
                const script = document.createElement('script');
                script.type = 'module';          
                script.src = "https://cdn.uselee.com/widget/main.js?v="+this.$api.moment().valueOf();      
                script.id = "dynamic-script";
                script.crossorigin = true;
                document.body.appendChild(script);
                const css = document.createElement('link');
                css.rel = 'stylesheet';    
                css.href = "https://cdn.uselee.com/widget/style.css?v="+this.$api.moment().valueOf();      
                css.id = "dynamic-css";
                css.crossorigin = true;
                document.body.appendChild(css);
            }, 1000);                  
        },  
        beforeDestroy(){
            var selectors = ['dynamic-script', 'dynamic-css'];
            selectors.forEach(sel => {
                let selector = document.getElementById(sel);
                if (selector) {
                    selector.remove();
                }
            })    
        },      
        methods: {                        
        },        
    }        
</script>
<!-- <style lang="scss">    
[class^="useleeWidget"] {  
    font-size: calc(1em );      
}

</style> -->